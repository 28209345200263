import React, {useEffect} from 'react'
import {HashRouter, Route, Switch} from 'react-router-dom'
import './App.sass'
import Dashboard from './screens/Dashboard'
import PopupEdit from './screens/PopupEdit'
import Login from './screens/Login'
import useSession from './hooks/useSession'
import backgroundImage from './assets/images/bg.png'
import Axios from 'axios'
import {translate} from './translations'

const App = () => {
    const {session, setSession} = useSession()

    useEffect(() => {
        (async () => {
            const {data: session} = await Axios.get('/me')
            setSession(session)
        })()
    }, [])

    useEffect(() => {
        if (!session?.theme) return

        document.title = `Share Locker | ${session?.theme.franchise_title}`
        document.querySelector('link[rel="icon"]').href = session?.theme.franchise_favicon
        document.querySelector('link[rel="apple-touch-icon"]').href = session?.theme.franchise_logo
        if (session?.theme.franchise_id === '30877') {
            document.querySelector('body').style.backgroundImage = `url(${backgroundImage})`
        }
    }, [session])

    return (
        <HashRouter>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/popups/:id" component={PopupEdit}/>
                <Route>
                    <h1>{translate('not-found')}</h1>
                </Route>
            </Switch>
        </HashRouter>
    )
}

export default App