import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import il from './il.json'
import it from './it.json'
import nl from './nl.json'
import pl from './pl.json'
import pt from './pt.json'

const translations = {de, en, es, fr, il, it, nl, pl, pt}

const [language] = navigator.language.split('-')

const translation = translations[language] || en

export function translate(key) {
    return translation[key] || ''
}