import React from 'react'
import md5 from 'crypto-js/md5'
import useSession from '../hooks/useSession'

const Navbar = ({children}) => {
    const {session} = useSession()

    return (
        <div className="navbar">
            <div className="navbar-menu">
                <div className="navbar-start">
                    <div className="navbar-item">
                        <div className="buttons">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-brand">
                <a className="navbar-item" href={process.env.REACT_APP_OFFICE_URL}>
                    <img src={session?.theme.franchise_logo}/>
                </a>
            </div>
            <div className="navbar-menu">
                <div className="navbar-end">
                    <div className="navbar-item">
                        <figure className="image is-24x24" title={session?.user.GR.name}>
                            <img className="is-rounded"
                                 src={`https://www.gravatar.com/avatar/${md5(session?.user.GR.name)}?s=48&d=mp`}/>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar