import React, {createContext, useContext, useState} from 'react'

const sessionContext = createContext({
    session: {
        user: {},
        theme: {
            franchise_logo: '',
            franchise_id: '',
            franchise_title: '',
            franchise_domain: '',
            franchise_colors: {
                default: {
                    primary_color: '',
                    secondary_color: '',
                },
            },
            franchise_favicon: '',
        },
    },
    setSession(session) {
    },
})

export const SessionContextProvider = ({children}) => {
    const [session, setSession] = useState(null)

    return (
        <sessionContext.Provider value={{session, setSession}}>
            {children}
        </sessionContext.Provider>
    )
}

const useSession = () => useContext(sessionContext)

export default useSession