import React, {Component} from 'react'
import {Area, AreaChart, Cell, Pie, PieChart, ResponsiveContainer} from 'recharts'
import moment from 'moment'
import {translate} from '../translations'

export default class PopupListItem extends Component {

    trend(list = []) {
        let [a, b] = [...list].reverse()
        let value = `${Math.floor(Math.abs(1 - (a.value / (b.value || 1))) * 100)}%`
        if (a.value > b.value) {
            return <span className="has-text-success">{value} &#8599;</span>
        }
        return <span className="has-text-danger">{value} &#8600;</span>
    }

    conversion(value) {
        if (value > 75) {
            return <span className="has-text-success">{translate('popup-list-item--conversion--good')}</span>
        }
        if (value > 25) {
            return <span className="has-text-warning">{translate('popup-list-item--conversion--medium')}</span>
        }
        return <span className="has-text-danger">{translate('popup-list-item--conversion--low')}</span>
    }

    render() {
        const {data, onClick} = this.props

        let statistics = [
            ...(new Array(24)).fill(null).map((_, i) => ({
                Index: i,
                Clicks: 0,
                Shares: 0,
            })).filter(a => !data.Statistics?.find(b => b.Index === a.Index)),
            ...(data.Statistics || []),
        ].sort((a, b) => b.Index - a.Index)

        const trends = statistics.map(s => ({value: s.Clicks}))

        const sum = statistics.reduce((a, b) => ({
            Clicks: a.Clicks + b.Clicks,
            Shares: a.Shares + b.Shares,
        }), {Clicks: 0, Shares: 0})
        const conversion = (sum.Shares / (sum.Clicks || 1)) * 100
        const conversions = [
            {value: conversion, color: '#66b3ff'},
            {value: 100 - conversion, color: '#f1f1f1'},
        ]

        return (
            <div className="list-item columns" onClick={onClick}>
                <div className="column is-6">
                    <div className="is-size-6">{data.Name}</div>
                    <div className="is-size-7">{moment(data.CreatedAt).format('LL')}</div>
                </div>
                <div className="column">
                    <div className="trends">
                        <ResponsiveContainer>
                            <AreaChart data={trends}>
                                <Area dataKey="value" stroke="#66b3ff" fill="#66b3ff77" type="monotone"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="is-size-7">{translate('popup-list-item--clicks')} {this.trend(trends)}</div>
                </div>
                <div className="column">
                    <div className="conversion-container">
                        <div className="conversion">
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie dataKey="value" data={conversions} fill="#f1f1f1" innerRadius={10}
                                         outerRadius={15}>
                                        {conversions.map((entry, index) => <Cell key={`cell-${index}`}
                                                                                 fill={entry.color}/>)}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            <div className="label">{Math.floor(conversion)}</div>
                        </div>
                        <div className="description is-size-7">
                            {translate('popup-list-item--score')}
                        </div>
                    </div>
                    <div
                        className="is-size-7">{translate('popup-list-item--conversion')} {this.conversion(conversion)}</div>
                </div>
            </div>
        )
    }
}
