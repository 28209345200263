import React from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import './index.sass'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {SessionContextProvider} from './hooks/useSession'

Axios.defaults.headers['Authorization'] = sessionStorage.getItem('token')
Axios.defaults.baseURL = process.env.REACT_APP_API_URL

Axios.interceptors.response.use(response => {
    if (response.headers.authorization) {
        Axios.defaults.headers['Authorization'] = response.headers.authorization
        sessionStorage.setItem('token', response.headers.authorization)
    }
    return response
}, error => {
    if (error.response && error.response.status === 401) {
        window.open('#/unauthorized', '_self')
    }
    return Promise.reject(error.response)
})

ReactDOM.render(
    <React.StrictMode>
        <SessionContextProvider>
            <App/>
        </SessionContextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
