import React, {Component} from 'react'
import ReactCrop from 'react-image-crop'
import './CropImageModal.sass'
import {translate} from '../translations'

export default class CropImageModal extends Component {
    constructor(props) {
        super(props)
        const {width, height} = this.props
        this.state = {
            open: false,
            file: null,
            fileURL: null,
            crop: {aspect: width / height, height},
            pixels: null,
        }
    }

    fileChange(e) {
        let [file] = e.target.files
        let fileURL = URL.createObjectURL(file)
        this.setState({file, fileURL, open: true})
        console.log(file)
    }

    cropChange(crop) {
        this.setState({crop})
    }

    completeCrop(crop, pixels) {
        this.setState({crop, pixels})
    }

    async acceptCrop(e) {
        e.preventDefault()
        const {pixels, fileURL} = this.state
        const {onChange, height, width} = this.props
        const image = await fileToImage(fileURL)
        const base64 = await getCroppedImage(image, pixels, height, width)
        onChange(base64)
        this.setState({open: false})
    }

    render() {
        const {open, fileURL, crop, pixels} = this.state
        const {value} = this.props
        return (
            <div className="CropImageModal">
                <div className="control-upload-preview">
                    <div className="file is-boxed is-fullwidth has-text-centered">
                        <label className="file-label">
                            <input className="file-input" type="file" onChange={this.fileChange.bind(this)}/>
                            <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">{translate('crop-image-modal--choose-a-file')}</span>
              </span>
                        </label>
                    </div>
                    <div className="preview" style={{backgroundImage: `url(${value})`}} hidden={!value}></div>
                </div>
                <div className={`modal ${open && 'is-active'}`}>
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <div className="has-text-centered">
                            <ReactCrop src={fileURL} crop={crop} onChange={this.cropChange.bind(this)}
                                       onComplete={this.completeCrop.bind(this)}/>
                        </div>
                        <p className="has-text-right">
                            <br/>
                            <button className="button is-light is-rounded" onClick={this.acceptCrop.bind(this)}
                                    disabled={!pixels}>{translate('crop-image-modal--accept')}
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

function fileToImage(file) {
    return new Promise((resolve, reject) => {
        try {
            const img = new Image()
            img.onload = () => resolve(img)
            img.src = file
        } catch (error) {
            reject(error)
        }
    })
}

function getCroppedImage(image, pixelCrop, finalHeight, finalWidth) {
    const width = pixelCrop.width * image.width / 100
    const height = pixelCrop.height * image.height / 100
    const x = pixelCrop.x * image.width / 100
    const y = pixelCrop.y * image.height / 100

    const canvas = document.createElement('canvas')
    canvas.width = finalWidth
    canvas.height = finalHeight
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
        image,
        x,
        y,
        width,
        height,
        0,
        0,
        canvas.width,
        canvas.height,
    )

    return canvas.toDataURL('image/jpeg')
}