import React, {useEffect} from 'react'
import query from 'query-string'
import Axios from 'axios'
import './Login.sass'

const Login = ({location}) => {
    const login = async (token, goto) => {
        Axios.defaults.headers['Authorization'] = `Bearer ${token}`
        window.open(`#${goto || '/dashboard'}`, '_self')
    }

    useEffect(() => {
        (async () => {
            const {token, goto} = query.parse(location.search)
            await login(token, goto)
        })()
    }, [])

    return (
        <div className="Login">
            <div className="container">
                <progress className="progress is-small is-primary" max="100"/>
            </div>
        </div>
    )
}


export default Login