import React, {Component} from 'react'
import './PopupScriptModal.sass'

/** versão original
 (function (window, document, tag, src, func, popup) {
  var element = document.createElement(tag);
  element.onload = function () { window[func](popup) }
  element.src = src;
  document.body.appendChild(element);
})(window, document, 'script', 'https://sharelocker.builderall.com/sharelocker.js', 'ShareLocker', 'SL-1')
 */

const popupScript = id => `
<!-- Share Locker -->
<script>
(function(s,h,r,l,c,k){
  var a=h.createElement(r);a.onload=function(){s[c](k)};a.src=l;h.body.appendChild(a);
})(window,document,'script','${window.location.origin}/lib/index.js','ShareLocker','SL-${Number(id).toString(36)}')
</script>
<!-- End Share Locker -->
`.trim()

export default class PopupScriptModal extends Component {

    copy(text) {
        return () => navigator.clipboard.writeText(text)
    }

    render() {
        const {popup, open, onClose} = this.props
        const s = popupScript(popup)

        return (
            <div className={`PopupScriptModal modal ${open && 'is-active'}`}>
                <div className="modal-background" onClick={onClose}></div>
                <div className="modal-content">
                    <div className="has-text-right">
                    </div>
                    <br/>
                    <pre>
            <div className="has-text-right">
              <a className="button is-rounded is-light is-small" onClick={this.copy(s)}><i className="fas fa-copy"></i></a>
            </div>
            <div className="script-container">
              {s}
            </div>
          </pre>
                </div>
                <button className="modal-close is-large" onClick={onClose}></button>
            </div>
        )
    }
}
