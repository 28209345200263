import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'
import Navbar from '../components/Navbar'
import './PopupEdit.sass'
import 'react-image-crop/lib/ReactCrop.scss'
import CropImageModal from '../components/CropImageModal'
import PopupScriptModal from '../components/PopupScriptModal'
import {translate} from '../translations'

const regexpYouTubeVideo = /(v=|\/embed\/|.be\/)([A-z0-9\-\_]+)/

export default class PopupEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                ID: 0,
                Name: '',
                AppID: '',
                PageToShare: '',
                ImageToShare: '',
                DisplayType: '',
                Content: '',
            },
            save: '',
            remove: '',
            showScript: false,
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props
        if (params.id !== 'new') {
            this.load(params.id)
        }
    }

    change(field, type = String) {
        return e => {
            this.setState({
                form: {
                    ...this.state.form,
                    [field]: type(e.target.value),
                },
            })
        }
    }

    changeDisplayType({target: {value}}) {
        const {form} = this.state
        this.setState({
            form: {
                ...form,
                DisplayType: value,
                Content: form.DisplayType === value ? form.Content : '',
            },
        })
    }

    changeImage(field) {
        return b => {
            this.setState({
                form: {
                    ...this.state.form,
                    [field]: b,
                },
            })
        }
    }

    extractVideo(url) {
        let match = String(url || '').match(regexpYouTubeVideo)
        if (!match || match.length < 3) {
            return
        }
        return match[2]
    }

    onlyNumbers(value) {
        return String(value).replace(/\D/g, '')
    }

    async load(id) {
        try {
            const {data} = await Axios.get(`/popup/${id}`)
            this.setState({
                form: {
                    ...this.state.form,
                    ...data,
                    Content: data.Content?.Data,
                    DisplayType: data.Content?.Type,
                    ImageToShare: data.ImageToShare?.Data,
                },
            })
        } catch (ex) {
            alert(ex?.data?.error)
        }
    }

    async save() {
        this.setState({save: 'loading'})
        try {
            const {form} = this.state
            const toSave = {
                ...form,
                Content: {
                    Type: form.DisplayType,
                    Data: form.Content,
                },
                ImageToShare: {
                    Type: 'image',
                    Data: form.ImageToShare,
                },
            }
            if (!form.ID) {
                const {data: {ID}} = await Axios.post('/popup', toSave)
                this.setState({form: {...form, ID}, showScript: true})
            } else {
                await Axios.patch('/popup', toSave)
            }
            this.setState({save: ''})
        } catch (ex) {
            this.setState({save: ex?.data?.error || 'Unknown error'})
        }
    }

    async remove() {
        this.setState({remove: 'loading'})
        try {
            const {form} = this.state
            await Axios.delete(`/popup/${form.ID}`)
            this.setState({remove: ''})
            window.open('#/dashboard', '_self')
        } catch (ex) {
            this.setState({remove: ex?.data?.error || 'Unknown error', loading: false})
        }
    }

    render() {
        const {form, save, remove, showScript} = this.state

        const content = () => {
            switch (form.DisplayType) {
                case 'image':
                    return <img className="preview__share-locker--content-image"
                                src={form.Content || 'https://picsum.photos/640/360'} alt="Content Image"/>
                case 'youtube-video':
                    return <iframe className="preview__share-locker--content-youtube"
                                   src={`https://www.youtube.com/embed/${this.extractVideo(form.Content) || '_iFzNayTP5E'}?autoplay=1&controls=0&disablekb=1&color=white`}
                    ></iframe>
                default:
                    return <div className="preview__share-locker--content-empty">{
                        translate('select-a-display-type')}
                    </div>
            }
        }

        return (
            <div className="PopupEdit container">
                <img src="https://picsum.photos/640/360" hidden alt="image cache"/>
                <div className="section">
                    <div className="card">
                        <div className="card-content">
                            <Navbar>
                                <Link
                                    className="button is-small is-light is-rounded animate__animated animate__fadeInRight"
                                    to="/dashboard">
                                    <i className="fas fa-arrow-left"/>
                                </Link>
                            </Navbar>
                            <hr/>
                            <div className="columns animate__animated animate__fadeIn">
                                <div className="column is-one-third">
                                    <form>
                                        <div className="field">
                                            <label className="label">{translate('popup-edit--name')}</label>
                                            <div className="control">
                                                <input className="input" type="text"
                                                       placeholder={translate('popup-edit--name')}
                                                       value={form.Name} onChange={this.change('Name')} required/>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">{translate('popup-edit--facebook-app-id')}</label>
                                            <div className="control">
                                                <input className="input" type="text"
                                                       placeholder={translate('popup-edit--facebook-app-id')}
                                                       value={form.AppID}
                                                       onChange={this.change('AppID', this.onlyNumbers)} required/>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">{translate('popup-edit--page-to-share')}</label>
                                            <div className="control">
                                                <input className="input" type="url"
                                                       placeholder={translate('popup-edit--page-to-share')}
                                                       value={form.PageToShare} onChange={this.change('PageToShare')}
                                                       required/>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">{translate('popup-edit--display-type')}</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={form.DisplayType}
                                                            onChange={this.changeDisplayType.bind(this)} required>
                                                        <option value="">
                                                            {translate('popup-edit--display-type--select-an-option')}
                                                        </option>
                                                        <option value="image">
                                                            {translate('popup-edit--display-type--image')}
                                                        </option>
                                                        <option value="youtube-video">
                                                            {translate('popup-edit--display-type--youtube-video')}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field" hidden={form.DisplayType !== 'youtube-video'}>
                                            <label
                                                className="label">{translate('popup-edit--youtube-video-url')}</label>
                                            <div className="control">
                                                <input className="input" type="url"
                                                       placeholder={translate('popup-edit--youtube-video-url')}
                                                       value={form.Content} onChange={this.change('Content')} required/>
                                            </div>
                                        </div>
                                        <div className="field" hidden={form.DisplayType !== 'image'}>
                                            <label className="label">{translate('popup-edit--image-to-display')}</label>
                                            <div className="control">
                                                <CropImageModal value={form.Content}
                                                                onChange={this.changeImage('Content')} height={720}
                                                                width={1280}/>
                                            </div>
                                        </div>
                                        <div className="buttons is-flex" hidden={remove === 'confirm'}>
                                            <a className={`button is-rounded is-primary is-fullwidth ${save === 'loading' && 'is-loading'}`}
                                               onClick={this.save.bind(this)}>{translate('popup-edit--save')}</a>&nbsp;
                                            <a className="button is-light is-rounded" hidden={!form.ID}
                                               onClick={() => this.setState({showScript: true})}>{translate('popup-edit--script')}</a>
                                            <a className="button is-rounded is-inverted is-danger"
                                               onClick={() => this.setState({remove: 'confirm'})}>
                                                <i className="far fa-trash-alt"/>
                                            </a>
                                        </div>
                                        <div className="remove-confirm" hidden={remove !== 'confirm'}>
                                            <div className="question">
                                                {translate('popup-edit--do-you-really-want-to-remove')}
                                            </div>
                                            <div className="is-flex">
                                                <a className={`button is-rounded is-danger is-fullwidth ${remove === 'loading' && 'is-loading'}`}
                                                   onClick={this.remove.bind(this)}>{translate('popup-edit--remove')}</a>&nbsp;
                                                <a className="button is-dark is-inverted is-rounded"
                                                   onClick={() => this.setState({remove: ''})}>{translate('popup-edit--cancel')}</a>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="column">
                                    <div className="preview__share-locker">
                                        {content()}
                                        <div className="preview__share-locker--footer">
                                            <div className="preview__share-locker--help">
                                                {translate('popup-edit--share-it-with-your-friends')}
                                            </div>
                                            <div className="preview__share-locker--share">
                                                <div className="preview__share-locker--share-text">
                                                    {translate('popup-edit--share')}
                                                </div>
                                                <div className="preview__share-locker--share-icon">
                                                    <img src={require('../assets/images/share.svg')} alt="Share Icon"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PopupScriptModal popup={form.ID} open={showScript} onClose={() => this.setState({showScript: false})}/>
            </div>
        )
    }
}
