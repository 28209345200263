import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'
import PopupListItem from '../components/PopupListItem'
import Navbar from '../components/Navbar'
import PopupStatistics from '../components/PopupStatistics'
import './Dashboard.sass'

export default class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            popups: [],
            selected: null,
        }
    }

    componentDidMount() {
        this.loadList()
    }

    async loadList() {
        try {
            const {data: popups} = await Axios.get('/popup')
            this.setState({popups})
            if (popups.length) this.loadDetail(popups[0])
        } catch (ex) {
            alert(ex?.data?.error || 'Unknown error')
        }
    }

    async loadDetail({ID}) {
        try {
            const {data} = await Axios.get(`/popup/${ID}`)
            this.setState({selected: data})
        } catch (ex) {
            alert(ex?.data?.error || 'Unknown error')
        }
    }

    render() {
        const {popups, selected} = this.state

        return (
            <div className="Dashboard container">
                <div className="section">
                    <div className="card">
                        <div className="card-content">
                            <Navbar>
                                <Link className="button is-small is-light is-rounded animate__animated animate__fadeIn"
                                      to="/popups/new" data-ba-i="sharelocker_add">
                                    <i className="fas fa-plus"></i>
                                </Link>
                            </Navbar>
                            <hr/>
                            <div className="columns animate__animated animate__fadeIn">
                                <div className="column">
                                    <div className="list-container" hidden={!popups?.length}>
                                        {popups.map((p, i) => (
                                            <PopupListItem key={i} data={p} onClick={() => this.loadDetail(p)}/>
                                        ))}
                                    </div>
                                    <div className="no-results" hidden={popups?.length}/>
                                </div>
                                <div className="column" hidden={!selected}>
                                    {selected ? <PopupStatistics data={selected}/> : '...'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
