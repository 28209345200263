import React, {Component} from 'react'
import {Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis} from 'recharts'
import {Link} from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/pt-br'
import {translate} from '../translations'

export default class PopupStatistics extends Component {
    historyLabel(index) {
        if (index === 0) return translate('popup-statistics--today')
        if (index === 1) return translate('popup-statistics--yesterday')
        return `${index} ${translate('popup-statistics--days-ago')}`
    }

    render() {
        const {data} = this.props

        const history = [
            ...(new Array(30)).fill(null).map((_, i) => ({
                Index: i,
                Clicks: 0,
                Shares: 0,
            })).filter(a => !data.Statistics?.find(b => b.Index === a.Index)),
            ...(data.Statistics || []),
        ].map(s => ({
            ...s,
            Conversion: (s.Shares / (s.Clicks || 1)) * 100,
            name: this.historyLabel(s.Index),
        })).sort((a, b) => b.Index - a.Index)

        return (
            <div className="PopupStatistics">
                <div className="columns">
                    <div className="column">
                        <div className="is-size-5">{data.Name}</div>
                        <div className="is-size-7">{moment('2020-07-02T15:04:05').format('LLL')}</div>
                    </div>
                    <div className="column has-text-right">
                        <Link className="button is-small is-light is-rounded" to={`/popups/${data.ID}`}>
                            <i className="fas fa-pen"></i>
                        </Link>
                    </div>
                </div>
                {history ? <div className="columns">
                    <div className="column" style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart data={history}>
                                <Tooltip content={({active, payload, label}) => (
                                    <span className="tag is-tooltip">
                                        <b>{label}</b><br/>
                                        <span className="has-text-link">&bull;</span>&nbsp;
                                        {translate('popup-statistics--shares')} {payload[1]?.value}<br/>
                                        <span className="has-text-info">&bull;</span>&nbsp;
                                        {translate('popup-statistics--clicks')} {payload[0]?.value}<br/>
                                        <span className="has-text-success">&bull;</span>&nbsp;
                                        {translate('popup-statistics--conversion')} {Math.floor(payload[2]?.value)}%
                                    </span>
                                )}/>
                                <XAxis dataKey="name" hide/>
                                <Bar dataKey="Clicks" type="monotone" stroke="#3273dc" fill="#3273dc" stackId="1"
                                     yAxisId="1"/>
                                <Bar dataKey="Shares" type="monotone" stroke="#3298dc" fill="#3298dc" stackId="1"
                                     yAxisId="1"/>
                                <Line dataKey="Conversion" type="monotone" stroke="#48c774" dot={false} strokeWidth="2"
                                      yAxisId="2"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div> : <div/>}
            </div>
        )
    }
}
